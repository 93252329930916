import { Component, signal } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HelloWorldResponse } from '@bannerflow/types.backend-template';
import { UIModule } from '@bannerflow/ui';
import { environment } from '../environments/environment';

@Component({
    selector: 'app-root',
    imports: [RouterOutlet, UIModule],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent {
    title = 'frontend-template';
    helloWorldReponse: HelloWorldResponse | undefined;

    loading = signal(true);

    constructor() {
        console.log(environment.stage);
        setTimeout(() => this.loading.set(false), 300);
    }
}

import { Component } from '@angular/core';
import { UIHeaderComponent, UIPREnvPickerComponent, UIPRServices } from '@bannerflow/ui';

@Component({
    imports: [UIPREnvPickerComponent, UIHeaderComponent],
    templateUrl: './demo-page.component.html',
    styleUrl: './demo-page.component.scss'
})
export class DemoPageComponent {
    listOfServices: UIPRServices[] = ['be-templ', 'fe-templ'];
}
